<template>
    <Layout>
        <Content style="background: #f9f9f9; min-width: 750px">
            <Card style="margin: 20px">
                <p slot="title">基本信息</p>
                <Button slot="extra" type="primary" @click="seeMore">查看更多资料</Button>
                <Form :model="answerForm" label-colon :label-width="80" style="display: flex; justify-content: center; flex-wrap: wrap">
                    <FormItem label="所属社区" style="width: 30%">{{ detailData.communityName }}</FormItem>
                    <FormItem label="姓名" style="width: 30%">{{ detailData.name }}</FormItem>
                    <FormItem label="性别" style="width: 30%">{{ detailData.sex == "1" ? "男" : detailData.sex == "2" ? "女" : "未知" }}</FormItem>
                    <FormItem :label="columnCode == '0001521' ? '人员类型' : '身份类型'" style="width: 30%">{{ detailData.houseHoldType ? detailData.houseHoldType : "未知" }}</FormItem>
                    <!-- detailData.houseHoldType == "0"
              ? "户籍人员"
              : detailData.houseHoldType == "1"
              ? "外来人员"
              : "未知" -->
                    <FormItem label="手机号" style="width: 30%">{{ detailData.mobile }}</FormItem>
                    <FormItem label="地址" style="width: 30%">{{ detailData.addr }}</FormItem>
                </Form>
            </Card>
        </Content>
        <Content style="background: #f9f9f9; min-width: 750px">
            <Card style="margin: 20px">
                <p slot="title">提交信息</p>
                <Form :model="answerForm" :label-width="100" label-colon>
                    <FormItem label="提交时间">{{ detailData.gmtCreate | filtersTime(this) }}</FormItem>
                    <FormItem label="标题">{{ detailData.title }}</FormItem>
                    <FormItem label="姓名">{{ detailData.userName }}</FormItem>
                    <FormItem label="联系方式">{{ detailData.mobile }}</FormItem>
                    <FormItem label="发现地址">{{ detailData.findAddress }}</FormItem>
                    <FormItem label="人员类型">{{ detailData.userType }}</FormItem>
                    <FormItem label="时间">{{ detailData.findTime ? this.$core.formatDate(new Date(detailData.findTime), "yyyy-MM-dd") : "" }}</FormItem>
                    <FormItem label="是否公开">{{ detailData.privacy == "0" ? "公开" : "保密" }}</FormItem>
                    <FormItem label="位置" v-if="columnCode != '0001521'">{{ detailData.position }}</FormItem>
                    <FormItem label="内容">
                        <p>{{ detailData.content }}</p>
                        <LiefengUpload
                            v-if="detailData.photo && detailData.photo.length > 0"
                            :fileLength="detailData.photo.length"
                            :defaultList="detailData.photo"
                            :showView="true"
                            :showDel="false"
                        ></LiefengUpload>
                    </FormItem>
                </Form>
            </Card>
        </Content>

        <!-- 查看的回复 -->
        <Content style="background: #f9f9f9; min-width: 750px" v-if="(seeAnswerTip == 'see' || seeAnswerTip == 'check') && detailData.eventIssueReplyDetailVo.staffName">
            <Card style="margin: 20px">
                <p slot="title">回复</p>
                <Form :model="answerForm" :label-width="100" label-colon>
                    <FormItem label="回复人">{{ detailData.eventIssueReplyDetailVo.staffName }}</FormItem>
                    <FormItem label="回复内容" v-if="detailData.eventIssueReplyDetailVo.content">{{ detailData.eventIssueReplyDetailVo.content }}</FormItem>
                    <FormItem label="是否公开">{{ detailData.eventIssueReplyDetailVo.privacy == "0" ? "公开" : "保密" }}</FormItem>
                    <FormItem label="驳回原因" v-if="detailData.eventIssueReplyDetailVo.reason">
                        <span style="color: red">{{ detailData.eventIssueReplyDetailVo.reason }}</span>
                    </FormItem>
                    <FormItem label="是否转交">{{ detailData.eventIssueReplyDetailVo.transfer == "0" ? "否" : "是" }}</FormItem>
                </Form>
            </Card>
        </Content>
        <!-- 回复 -->
        <Content style="background: #f9f9f9; min-width: 750px" v-if="seeAnswerTip == 'answer'">
            <Card style="margin: 20px">
                <p slot="title">回复</p>
                <Form :model="answerForm" :rules="validateForm" :label-width="100" label-colon ref="answerForm">
                    <FormItem label="是否公开">
                        <RadioGroup v-model="answerForm.privacy">
                            <Radio label="0" v-if="detailData.privacy == '0'">公开</Radio>
                            <Radio label="1">保密</Radio>
                            <Tooltip placement="top" :delay="500">
                                <Icon type="md-help-circle" style="margin: 10px"></Icon>
                                <div slot="content">
                                    <p>1.如果提交者选择的是保密，回复时只能默认保密；</p>
                                    <p>2.如果提交者选择的是公开，回复时可根据情况选择公开或保密回复；</p>
                                    <p>3.保密回复则只有提交者本人可见回复内容，公开回复则社区的居民均可见回复内容。</p>
                                </div>
                            </Tooltip>
                        </RadioGroup>
                    </FormItem>
                    <FormItem label="是否转交">
                        <RadioGroup v-model="answerForm.transfer" @on-change="$refs.answerForm.resetFields()">
                            <Radio label="0">否</Radio>
                            <Radio label="1">是</Radio>
                        </RadioGroup>
                    </FormItem>
                    <FormItem label="回复内容" prop="content" v-if="answerForm.transfer == '0'">
                        <Input v-model="answerForm.content" :maxlength="400" show-word-limit type="textarea" :rows="4" placeholder="请输入回复内容，限400字" />
                    </FormItem>
                    <FormItem label="转交机构" v-if="answerForm.transfer == '1'">
                        <Select v-model="answerForm.transferOrgCode" style="width: 200px" @on-change="isShowItemEvent">
                            <Option v-for="(item, index) in transferOrgList" :value="item.orgCode" :key="index">{{ item.orgName }}</Option>
                        </Select>
                    </FormItem>
                    <FormItem v-if="answerForm.transferOrgCode == 'NHWYJG001'">
                        <span slot="label" class="validate">请选择事项</span>
                        <Input v-model.trim="answerForm.itemVal" style="width: 30%" @on-change="searchItemTree" placeholder="输入事项关键字搜索">
                            <Icon type="ios-search" slot="suffix" />
                        </Input>
                        <div style="border: 1px solid #e8eaec; margin-top: 10px">
                            <div style="display: flex; line-height: 50px; font-size: 16px; color: #515a6e">
                                <div style="flex: 1; text-align: center; border-bottom: 1px solid #e8eaec">一级事项</div>
                                <div style="flex: 1; text-align: center; border-left: 1px solid #e8eaec; border-bottom: 1px solid #e8eaec">二级事项</div>
                                <div style="flex: 1; text-align: center; border-left: 1px solid #e8eaec; border-bottom: 1px solid #e8eaec">三级事项</div>
                            </div>
                            <div style="display: flex">
                                <RadioGroup
                                    v-if="oneTreeList.length > 0"
                                    v-model="itemTreeVal.oneTreeVal"
                                    style="flex: 1; padding: 0 20px; border-right: 1px solid #e8eaec"
                                    @on-change="val => getItemTreeData(val, answerForm.itemVal, [], 'twoTreeList')"
                                >
                                    <Radio style="width: 100%" :label="item.id" v-for="item in oneTreeList" :key="item.id">{{ item.itemName }}</Radio>
                                </RadioGroup>
                                <div v-else style="flex: 1; padding: 0 20px; text-align: center; border-right: 1px solid #e8eaec">未查询到相关事项</div>
                                <RadioGroup
                                    v-if="twoTreeList.length > 0"
                                    v-model="itemTreeVal.twoTreeVal"
                                    style="flex: 1; padding: 0 20px; border-right: 1px solid #e8eaec"
                                    @on-change="val => getItemTreeData(val, answerForm.itemVal, [], 'threeTreeList')"
                                >
                                    <Radio style="width: 100%" :label="item.id" v-for="item in twoTreeList" :key="item.id">{{ item.itemName }}</Radio>
                                </RadioGroup>
                                <div v-else style="flex: 1; padding: 0 20px; text-align: center; border-right: 1px solid #e8eaec">未查询到相关事项</div>
                                <RadioGroup v-if="threeTreeList.length > 0" v-model="itemTreeVal.threeTreeVal" style="flex: 1; padding: 0 20px">
                                    <Radio style="width: 100%" :label="item.itemCode" v-for="item in threeTreeList" :key="item.id">{{ item.itemName }}</Radio>
                                </RadioGroup>
                                <div v-else style="flex: 1; text-align: center; padding: 0 20px; border-right: 1px solid #e8eaec">未查询到相关事项</div>
                            </div>
                        </div>
                    </FormItem>

                    <FormItem label="驳回原因" v-if="detailData.eventIssueReplyDetailVo.reason">
                        <p style="color: red">
                            {{ detailData.eventIssueReplyDetailVo.reason }}
                        </p>
                    </FormItem>
                </Form>
            </Card>
        </Content>

        <!-- 有呼必应查看工单 -->
        <Content style="background: #f9f9f9; min-width: 750px" v-if="seeAnswerTip == 'seeManage'">
            <Card style="margin: 20px">
                <p slot="title">提交有呼必应信息</p>
                <Form :model="answerForm" :label-width="100" label-colon>
                    <FormItem label="提交人">{{ detailData.staffName }}</FormItem>
                    <FormItem label="提交时间">{{ detailData.transferTime | filtersTime(this) }}</FormItem>
                    <FormItem label="事项类型">{{ detailData.itemName }}</FormItem>
                </Form>
            </Card>
        </Content>
        <Content style="background: #f9f9f9; min-width: 750px" v-if="seeAnswerTip == 'seeManage'">
            <Card style="margin: 20px">
                <p slot="title">有呼必应处理结果信息</p>
                <Form :model="answerForm" :label-width="100" label-colon>
                    <FormItem label="处理结果">{{ detailData.finish == "1" ? "处理中" : detailData.finish == "2" ? "处理成功" : detailData.finish == "3" ? "处理失败" : "未知" }}</FormItem>
                    <FormItem label="处理时间">{{ detailData.handleTime | filtersTime(this) }}</FormItem>
                    <FormItem label="办理部门">{{ detailData.handleOrgName || "未知" }}</FormItem>
                    <FormItem label="办理情况说明">{{ detailData.handleResult || "未知" }}</FormItem>
                </Form>
            </Card>
        </Content>

        <!-- 驳回 -->
        <LiefengModal title="驳回原因" :value="reasonStatus" @input="reasonStatusFn" width="600px">
            <template #contentarea>
                <Form ref="reasonForm" :model="reasonForm" :rules="validateReason">
                    <FormItem prop="reason">
                        <Input v-model="reasonForm.reason" :rows="4" maxlength="100" show-word-limit type="textarea" placeholder="请输入驳回原因" />
                    </FormItem>
                </Form>
            </template>
            <template #toolsbar>
                <Button type="info" style="margin-right: 10px" @click="reasonStatus = false">取消</Button>
                <Button type="primary" @click="saveReasonPublish" :loading="reasonLoading">
                    <span v-if="!reasonLoading">提交</span>
                    <span v-else>正在提交...</span>
                </Button>
            </template>
        </LiefengModal>
    </Layout>
</template>

<script>
import LiefengUpload from "@/components/LiefengUpload"
import LiefengModal from "@/components/LiefengModal"
export default {
    props: ["detailData", "seeAnswerTip", "columnCode"],
    filters: {
        filtersTime(val, that) {
            if (val) {
                return that.$core.formatDate(new Date(val), "yyyy-MM-dd hh:mm:ss")
            } else {
                return "未知"
            }
        },
    },
    data() {
        return {
            // 回复
            answerForm: {
                transfer: "0",
                privacy: "1",
                content: "",
                transferOrgCode: "",
                itemCode: "",
                itemVal: "",
            }, // itemVal事项搜索
            validateForm: {
                content: [{ required: true, message: "请输入回复内容", trigger: "blur" }],
            },
            //转交机构列表
            transferOrgList: [],
            //事项数列表
            oneTreeList: [],
            twoTreeList: [],
            threeTreeList: [],
            itemTreeData: [],
            itemTreeVal: {
                oneTreeVal: "",
                twoTreeVal: "",
                threeTreeVal: "",
            },
            //   驳回状态
            reasonStatus: false,
            validateReason: {
                reason: [{ required: true, message: "请输入驳回原因", trigger: "blur" }],
            },
            reasonForm: { reason: "" },
            reasonLoading: false,
        }
    },
    methods: {
        reasonStatusFn(status) {
            this.reasonStatus = status
        },
        //   驳回/发布提交
        saveReasonPublish() {
            let params = {
                id: this.detailData.eventIssueReplyDetailVo.id,
            }
            if (this.reasonStatus) {
                this.$refs.reasonForm.validate(status => {
                    if (status) {
                        params.status = "3"
                        params.reason = this.reasonForm.reason
                        this.$post("/gateway/syinfopublish/api/pc/eventIssue/updateStatus", params, {
                            "Content-Type": "application/json",
                        }).then(res => {
                            if (res.code == 200) {
                                this.$Message.success({
                                    background: true,
                                    content: "提交成功",
                                })
                                this.$emit("submitSuccess")
                                this.$emit("search")
                            } else {
                                this.$Message.error({
                                    background: true,
                                    content: res.desc,
                                })
                            }
                        })
                    }
                })
            } else {
                params.status = "2"
                this.$post("/gateway/syinfopublish/api/pc/eventIssue/updateStatus", params, {
                    "Content-Type": "application/json",
                }).then(res => {
                    if (res.code == 200) {
                        this.$Message.success({
                            background: true,
                            content: "发布成功",
                        })
                        this.$emit("submitSuccess")
                        this.$emit("search")
                    } else {
                        this.$Message.error({
                            background: true,
                            content: res.desc,
                        })
                    }
                })
            }
        },
        // 选择转交机构，强制更新页面
        isShowItemEvent(val) {
            if (val == "NHWYJG001") {
                this.$forceUpdate()
            }
        },
        //   搜索事项树
        searchItemTree(val) {
            this.getItemTreeData("", this.answerForm.itemVal, this.itemTreeData, "oneTreeList")
        },
        // 封装为事项的三级赋值   id为选中的id,searchVal为搜索值，arr为当前选中项的children,list为需要赋值的值
        getItemTreeData(id, searchVal, arr, list) {
            if (list == "oneTreeList") {
                if (searchVal != "") {
                    this[list] = []
                    arr.map(item => {
                        if (JSON.stringify(item).indexOf(searchVal) != -1) {
                            this[list].push(item)
                        }
                    })
                } else {
                    this[list] = JSON.parse(JSON.stringify(arr))
                }
                if (this[list].length > 0) {
                    this.itemTreeVal.oneTreeVal = this[list][0].id
                    this.getItemTreeData(this.itemTreeVal.oneTreeVal, this.answerForm.itemVal, this[list][0], "twoTreeList")
                } else {
                    this.twoTreeList = []
                    this.threeTreeList = []
                }
            } else if (list == "twoTreeList") {
                this.oneTreeList.map((item, index) => {
                    if (item.id == id) {
                        this[list] = JSON.parse(JSON.stringify(item.children))
                    }
                })
                if (this[list].length > 0) {
                    if (searchVal == "") {
                        this.itemTreeVal.twoTreeVal = this[list][0].id
                    } else {
                        //找到搜索字段，并选中
                        let flag = false
                        this[list].map((item, index) => {
                            if (JSON.stringify(item).indexOf(searchVal) != -1) {
                                this.itemTreeVal.twoTreeVal = this[list][index].id
                                flag = true
                            }
                        })
                        if (!flag) {
                            this.itemTreeVal.twoTreeVal = this[list][0].id
                        }
                    }
                    this.getItemTreeData(this.itemTreeVal.twoTreeVal, this.answerForm.itemVal, this[list][0], "threeTreeList")
                } else {
                    this.threeTreeList = []
                }
            } else {
                this.twoTreeList.map((item, index) => {
                    if (item.id == id) {
                        this[list] = JSON.parse(JSON.stringify(item.children))
                    }
                })
                if (this[list].length > 0) {
                    if (searchVal == "") {
                        this.itemTreeVal.threeTreeVal = this[list][0].itemCode
                    } else {
                        //找到搜索字段，并选中
                        let flag = false
                        this[list].map((item, index) => {
                            if (JSON.stringify(item).indexOf(searchVal) != -1) {
                                this.itemTreeVal.threeTreeVal = this[list][index].itemCode
                                flag = true
                            }
                        })
                        if (!flag) {
                            this.itemTreeVal.threeTreeVal = this[list][0].itemCode
                        }
                    }
                }
            }
        },
        seeMore() {
            this.$Message.info({
                background: true,
                content: "敬请期待！",
            })
        },
        submit() {
            this.$post(
                "/info/api/pc/eventIssue/createEventIssueReply",
                {
                    content: this.answerForm.transfer == "0" ? this.answerForm.content : null,
                    custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                    issueId: this.detailData.id,
                    itemCode: this.itemTreeVal.threeTreeVal,
                    oemCode: parent.vue.oemInfo.oemCode,
                    orgCode: parent.vue.loginInfo.userinfo.orgCode,
                    privacy: this.answerForm.privacy,
                    staffName: parent.vue.loginInfo.userinfo.realName,
                    transfer: this.answerForm.transfer,
                    transferOrgCode: this.answerForm.transfer == "1" ? this.answerForm.transferOrgCode : null,
                },
                { "Content-Type": "application/json" }
            )
                .then(res => {
                    if (res.code == 200) {
                        this.$Message.success({
                            background: true,
                            content: "提交成功！",
                        })
                        this.$emit("submitSuccess")
                        this.$emit("search")
                    } else {
                        this.$Message.error({
                            background: true,
                            content: res.desc,
                        })
                    }
                })
                .catch(err => {
                    console.log(err)
                    this.$Message.error({
                        background: true,
                        background: "提交失败，请联系管理员处理",
                    })
                })
        },
    },
    created() {
        // 获取转交机构列表
        this.$get("/info/api/pc/eventIssue/getTransferOrgList", {
            issueId: this.detailData.id,
            communityCode: this.detailData.dataScopeId,
            oemCode: parent.vue.oemInfo.oemCode,
        }).then(res => {
            if (res.code == 200) {
                this.transferOrgList = res.dataList
            }
        })

        // 获取事项列表
        this.$get("/info/api/pc/eventIssue/getEventIssueItemTree").then(res => {
            if (res.code == 200) {
                this.getItemTreeData("", this.answerForm.itemVal, res.dataList[0].children, "oneTreeList")
                this.itemTreeData = res.dataList[0].children
            }
        })
        this.answerForm.privacy = this.detailData.eventIssueReplyDetailVo.privacy ? this.detailData.eventIssueReplyDetailVo.privacy : this.detailData.privacy
        this.answerForm.transfer = this.detailData.eventIssueReplyDetailVo.transfer ? this.detailData.eventIssueReplyDetailVo.transfer : "0"
        this.answerForm.content = this.detailData.eventIssueReplyDetailVo.content
        this.answerForm.transferOrgCode = this.detailData.eventIssueReplyDetailVo.transferOrgCode

        this.$get("/dictlist/api/lfcommon/pc/dict/selectDictDataList", {
            appCode: "SUIYUE",
            dictType: "USER_TYPE",
        }).then(res => {
            if (res.code == 200) {
                res.dataList.map(e => {
                    res.dataList.map(item => {
                        if (this.detailData.userType == item.dictKey) this.detailData.userType = item.dictValue
                    })
                })
            }
        })
    },
    components: {
        LiefengUpload,
        LiefengModal,
    },
}
</script>
    
<style scoped lang='less'>
/deep/.validate {
    &::before {
        content: "*";
        display: inline-block;
        margin-right: 4px;
        line-height: 1;
        font-family: SimSun;
        font-size: 14px;
        color: #ed4014;
    }
}
/deep/.ivu-tooltip-inner {
    max-width: 100%;
}
</style>